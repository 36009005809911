export const socialData = [
  {
    type: "youtube",
    //link: "https://www.youtube.com/c/AgustinNavarroGaldon?sub_confirmation=1",
    link: "https://www.youtube.com/",
  },
  {
    type: "twitter",
    //link: "https://twitter.com/xAgustin93",
    link: "https://twitter.com",
  },
  {
    type: "facebook",
    //link: "https://www.facebook.com/AgustinNavarroGaldon",
    link: "https://www.facebook.com",
  },
  {
    type: "linkedin",
    //link: "https://www.linkedin.com/in/agustin93?originalSubdomain=es",
    link: "https://www.linkedin.com/",
  },
];
