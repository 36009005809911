import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { WebRouter, AdminRouter } from "./router";
import { AuthProvider, FolioProvider } from "./contexts";
import ReactGA from "react-ga";

const TRACKING_ID = "G-MD21C7GL3H"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AuthProvider>
      <FolioProvider>
        <BrowserRouter>
          <WebRouter />
          <AdminRouter />
        </BrowserRouter>
      </FolioProvider>
    </AuthProvider>
  );
}
